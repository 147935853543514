@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
    height: 100%;
    margin: 0 auto;
}

h1 {
    text-align: center;
    color: #333333;
    padding-bottom: 32px;
}

.logo {
    background-color: rgb(0, 46, 93);
    margin-bottom: 64px;
}

.container {
    margin: 0 auto;
    max-width: 1200px;

    display: grid;
    grid-template-columns: repeat(8, 1fr);
    /*grid-template-rows: repeat(8, 1fr);*/
    gap: 20px;

}

.container--small {
    align-content: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 900px;
}

.button-primary {
    align-items: center;
    background-color: #bada55;
    border-radius: 300px;
    color: #003a78;
    display: flex;
    font-size: 20px;
    height: 49px;
    justify-content: center;
    margin-right: 16px;
    padding: 6px 12px;
    text-decoration: none;
    transition: color 0.25s ease-out,color 0.25s ease-out;
    width: 152px;
}

.button-primary:last-of-type {
    margin-right: 0;
}

.button-primary:hover {
    color: #fff;
}

.card {
    background-color: rgb(0, 46, 93);
    background-image: url("../public/images/Logo_TWW_2-farbig.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    animation: 2s hideCard linear;
}

.card img {
    background-color: rgb(0, 46, 93);
    width: 100%;
    height: 100%;
     /*transform: scale(0);*/
    opacity: 0;
    animation: 2s hideImage linear;
}


.card.active {
    transform: rotateY(0);
}

.card.active img {
    background-color: #fff;
    border: 1px solid #cacaca;
    box-shadow: rgba(25, 25, 25, 0.1) 0 10px 15px;
    opacity: 1;
}

.unmatch {
    background-color: rgb(0, 46, 93);
    animation: 2s hideCard linear;
}

.footer {
    display: flex;
    justify-content: center;
    padding-top: 32px;
}

@keyframes hideCard {
    0%, 70% {
        transform: rotateX(0deg);
    }
    100% {
        transform: rotateX(180deg);
    }
}

@keyframes hideImage {
    0%, 84% {
        opacity: 1;
    }
    85% {
        opacity: 0;
    }
}
